import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/bethel-irene-reisdorph.jpg";
  const fullName = "Bethel Irene Reisdorph";
  const zoomUrl =
    "https://us02web.zoom.us/j/89625630135?pwd=TkRZd3VMMU9Hbmc4eVpaMzB2VHpLZz09";
  const zoomPhoneNumber = "(301) 715-8592";
  const zoomMeetingId = "896 2563 0135";
  const zoomMeetingPasscode = "295610";
  const birthDate = new Date(1927, 7 - 1, 22);
  const deathDate = new Date(2020, 12 - 1, 23);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 10);
  const memorialTime = "2pm MST";
  const inviteText =
    "Bethel Reisdorph passed into eternal life on December 23, 2020 at the age of 93. Her family invites you to a virtual service celebrating her life.";
  const obituary = `Bethel Irene Reisdorph was born to Roy Harm and Bethel Irene Harm on July 22, 1927, on their farm near Ellendale, North Dakota.  She joined two brothers and two sisters, all under the age of 8.  Her mother died in childbirth when Bethel came into the world.    As an infant, she was taken in by friends of the family, Albert and Bessie Heimke, and gained 4 older foster sisters.  She was raised by the Heimkes, while also being cared for by her father and her paternal grandmother.  She was close with her four siblings throughout her growing-up years.  
  \nShe quietly departed this life on the morning of December 23, 2020 where she was on hospice care in her nursing home in Castle Rock, Colorado.  Her steadfast Christian life was as beautiful as the flowers and birds she loved in life, and her influence will long be felt in the lives of her children, grandchildren, and everyone who knew her.  
  \nBethel professed her faith in Jesus Christ at an early age and was always a dedicated member of Nazarene churches in Ellendale, North Dakota, Aberdeen, South Dakota, and Colorado Springs, Colorado.  She was the church organist and pianist in Ellendale, North Dakota and Aberdeen, South Dakota churches, and held positions in missionary and youth programs.  
  \nBethel attended schools in Ellendale, North Dakota, and acquired a Licensed Practical Nurse certification by mail in her 20’s.  
  \nBethel was united in marriage to Carroll Calvin (CC)  Reisdorph on April 6, 1945 in rural Frederick South Dakota.    At the time of CC’s death in 2019, they had been married 73 years.  Bethel and CC had five children and adopted two Korean daughters.  The couple lived first in rural Ellendale, North Dakota, and in 1952 they moved to their farm in rural Frederick, South Dakota.  They subsequently lived in Aberdeen, South Dakota, and for the past 11 years in Colorado Springs, Colorado.  Bethel resided in an assisted living facility in Castle Rock, Colorado until her death.  
  \nOver the years, Bethel raised flocks of sheep, litters of pigs, gaggles of geese, flocks and flocks of chickens, and a small herd of milk cows.  She was a practical nurse at the hospital in Ellendale, North Dakota.  She established and ran a ceramics business in rural Frederick, South Dakota.  She raised finches, canaries, parakeets, button quail, and exotic birds for a number of years in Aberdeen, South Dakota.  Her interests were many and varied.  She was an avid crossword puzzle solver, played her organ in her home, and was a voracious reader and book collector.  She was an excellent cook and baker.   Her children, grandchildren, and great grandchildren were her heart.  Her beautiful spirit and sense of humor endeared her to everyone she met – she was much loved.  
  \nBethel was preceded in death by her husband, CC; one daughter, Kristine; two brothers, one sister, and four foster sisters; parents and foster parents, and her stepmother.  She was survived by one sister, Bette, who followed her into eternity in paradise just 5 days after Bethel died; and is survived by Bobbie Schopp and Lorene Hintz, her stepsisters.   She also leaves four sons and their wives:  Steve and Golda Reisdorph, Bruce and Shirley Reisdorph, Kent and Jennifer Reisdorph, and Rick and Nichole Reisdorph.  In addition, she is survived by two daughters:  Kara and husband Robb Ganje and Georgia Burleson.  She has also left a rich legacy for 21 grandchildren, 15 great-grandchildren and one great-granddaughter to arrive in Spring of 2021.  
  \nA Zoom memorial service will be held in early January.  No flowers or gifts please.  If you wish, please make a contribution to a family in your community who needs Covid relief.  
  `;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/BethelRecording720.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    ></StoryPage>
  );
};

export default ThisPage;
